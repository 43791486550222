<template>
  <div class="admin-dashboard">
    <h2>Admin Dashboard</h2>
    <button class="btn btn-primary" @click="resetView(); showCreateGameForm = true">Create New Game</button>
    <button class="btn btn-primary" @click="showManageGamesModal = true">Manage Games</button>
    <button class="btn btn-primary" @click="openSearchGamesModal">Search Games</button>
    <button class="btn btn-primary" @click="openManageSquadsModal">Manage Squads</button>
    <button class="btn btn-primary" @click="openReviewLeaderboardsModal">Review Leaderboards</button>

    <div v-if="showCreateGameForm" class="create-game-form">
      <h3>Create a New Game</h3>
      <form @submit.prevent="createGame">
        <div class="form-group">
          <label for="questionText">Question Text:</label>
          <input v-model="questionText" id="questionText" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="questionShortDescription">Question Short Description:</label>
          <input v-model="questionShortDescription" id="questionShortDescription" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="liveDate">Game Live Date:</label>
          <input v-model="liveDate" type="date" id="liveDate" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="basePoints">Base Point Value:</label>
          <input v-model="basePoints" type="number" id="basePoints" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="correctAnswerCount">Number of Correct Answers:</label>
          <input v-model.number="correctAnswerCount" type="number" id="correctAnswerCount" class="form-control" @input="generateCorrectAnswerFields" required />
        </div>
        <div v-if="correctAnswerCount > 0" class="form-group">
          <label>Correct Answers:</label>
          <div v-for="(answer, index) in correctAnswers" :key="index" class="answer-field">
            <input v-model="correctAnswers[index]" placeholder="Enter correct answer" class="form-control" required />
          </div>
        </div>
        <div class="form-group">
          <label for="incorrectAnswerCount">Number of Incorrect Answers:</label>
          <input v-model.number="incorrectAnswerCount" type="number" id="incorrectAnswerCount" class="form-control" @input="generateIncorrectAnswerFields" required />
        </div>
        <div v-if="incorrectAnswerCount > 0" class="form-group">
          <label>Incorrect Answers:</label>
          <div v-for="(answer, index) in incorrectAnswers" :key="index" class="answer-field">
            <input v-model="incorrectAnswers[index]" placeholder="Enter incorrect answer" class="form-control" required />
          </div>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-success">Create Game</button>
          <button type="button" class="btn btn-secondary" @click="resetForm">Cancel</button>
        </div>
      </form>
    </div>

    <!-- Manage Games Modal -->
    <div v-if="showManageGamesModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Manage Games</h3>
        <ListGames @edit-game="openEditModal" />
        <button class="btn btn-secondary" @click="showManageGamesModal = false">Close</button>
      </div>
    </div>

    <div v-if="showManageSquadsModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Manage Squads</h3>
        <table>
          <thead>
            <tr>
              <th>Squad ID</th>
              <th>Squad Name</th>
              <th>Number of Members</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="squad in squads" :key="squad.id">
              <td>{{ squad.id }}</td>
              <td>{{ squad.name }}</td>
              <td @click="viewSquadMembers(squad.id)" style="cursor: pointer; color: blue;">
                {{ squad.memberCount }}
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-secondary" @click="closeManageSquadsModal">Close</button>
      </div>
    </div>

    <!-- Button to open UserManagement modal -->
    <button class="btn btn-primary" @click="openUserManagementModal">Manage Users</button>

    <!-- UserManagement Modal -->
    <div v-if="showUserManagementModal" class="modal-overlay">
      <div class="modal-content">
        <h3>User Management</h3>
        <UserManagement />
        <button class="btn btn-secondary" @click="closeUserManagementModal">Close</button>
      </div>
    </div>

    <!-- Edit Game Modal -->
    <div v-if="showEditModal" class="modal">
      <div class="modal-content">
        <h3>Edit Game</h3>
        <form @submit.prevent="updateGame">
          <div class="form-group">
            <label for="editQuestionText">Question Text:</label>
            <input v-model="editingGame.questionText" id="editQuestionText" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="editQuestionShortDescription">Question Short Description:</label>
            <input v-model="editingGame.questionShortDescription" id="editQuestionShortDescription" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="editLiveDate">Live Date:</label>
            <input v-model="editingGame.liveDate" type="date" id="editLiveDate" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="editBasePoints">Base Points:</label>
            <input v-model.number="editingGame.basePoints" type="number" id="editBasePoints" class="form-control" required />
          </div>
          <div class="form-group">
            <label>Correct Answers:</label>
            <div v-for="(answer, index) in editingGame.correctAnswers" :key="'correct-'+index" class="answer-field">
              <input v-model="editingGame.correctAnswers[index]" :placeholder="'Correct answer ' + (index + 1)" class="form-control" required />
            </div>
            <button type="button" @click="addAnswer('correct')" class="btn btn-secondary">Add Correct Answer</button>
          </div>
          <div class="form-group">
            <label>Incorrect Answers:</label>
            <div v-for="(answer, index) in editingGame.incorrectAnswers" :key="'incorrect-'+index" class="answer-field">
              <input v-model="editingGame.incorrectAnswers[index]" :placeholder="'Incorrect answer ' + (index + 1)" class="form-control" required />
            </div>
            <button type="button" @click="addAnswer('incorrect')" class="btn btn-secondary">Add Incorrect Answer</button>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-success">Update Game</button>
            <button type="button" class="btn btn-secondary" @click="closeEditModal">Cancel</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Members Modal -->
    <div v-if="showMembersModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Squad Members</h3>
        <ul>
          <li v-for="member in squadMembers" :key="member">{{ member }}</li>
        </ul>
        <button class="btn btn-secondary" @click="closeMembersModal">Close</button>
      </div>
    </div>

    <!-- Review Leaderboards Modal -->
    <div v-if="showReviewLeaderboardsModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Review Leaderboards</h3>
        <table class="leaderboard-table">
          <thead>
            <tr>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="board in leaderboards" :key="board.id">
              <td>{{ formatDate(board.startDate) }}</td>
              <td>{{ formatDate(board.endDate) }}</td>
              <td>
                <button class="btn btn-primary" @click="reviewLeaderboard(board)">Review</button>
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-secondary" @click="closeReviewLeaderboardsModal">Close</button>
      </div>
    </div>

    <!-- Leaderboard Details Modal -->
    <div v-if="showLeaderboardDetailsModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Leaderboard Details</h3>
        <h4>Week of {{ selectedLeaderboard ? formatDate(selectedLeaderboard.startDate) : '' }}</h4>
        <table class="leaderboard-details-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Username</th>
              <th>User ID</th>
              <th>Games Played</th>
              <th>Game Details</th>
              <th>Calculated Score</th>
              <th>Stored Score</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(player, index) in sortedLeaderboardDetails" 
                :key="player.uid"
                :class="{ 'score-mismatch': player.calculatedScore !== player.weeklyScore }">
              <td>{{ index + 1 }}</td>
              <td>{{ player.username }}</td>
              <td>{{ player.uid }}</td>
              <td>{{ player.games?.length || 0 }}</td>
              <td class="game-details">
                <div v-for="(game, gameIndex) in player.games" :key="gameIndex">
                  Game {{ gameIndex + 1 }}: {{ game.score }} points
                  <br>
                  "{{ game.questionText || 'Unknown Question' }}"
                  <br>
                  {{ formatDate(game.timestamp) }}
                  <hr v-if="gameIndex < player.games.length - 1">
                </div>
              </td>
              <td>{{ player.calculatedScore }}</td>
              <td>{{ player.weeklyScore }}</td>
              <td>
                <button 
                  v-if="player.calculatedScore !== player.weeklyScore"
                  class="btn btn-warning"
                  @click="fixPlayerScore(player)"
                >
                  Fix Score
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-secondary" @click="closeLeaderboardDetailsModal">Close</button>
      </div>
    </div>

    <!-- Search Games Modal -->
    <div v-if="showSearchGamesModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Search Games</h3>
        <div class="form-group">
          <input 
            v-model="searchQuery" 
            class="form-control" 
            placeholder="Search by question text or description..."
            @input="searchGames"
          />
        </div>
        <div class="search-results">
          <div v-if="searchResults.length > 0">
            <table class="leaderboard-table">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Description</th>
                  <th>Live Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="game in searchResults" :key="game.id">
                  <td>{{ game.questionText }}</td>
                  <td>{{ game.questionShortDescription }}</td>
                  <td>{{ formatDate(game.liveDate) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else-if="searchQuery" class="no-results">
            No games found matching your search.
          </div>
        </div>
        <button class="btn btn-secondary" @click="closeSearchGamesModal">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp, doc, updateDoc, getDocs, getDoc } from 'firebase/firestore';
import ListGames from '../components/ListGames.vue';
import UserManagement from '../components/UserManagement.vue';
import { parse, format, isValid, setHours, setMinutes, setSeconds } from 'date-fns';

export default {
  components: {
    ListGames,
    UserManagement
  },
  data() {
    return {
      showCreateGameForm: false,
      showUserManagement: false,
      questionText: '',
      questionShortDescription: '',
      liveDate: '',
      basePoints: 100,
      correctAnswerCount: 0,
      incorrectAnswerCount: 0,
      correctAnswers: [],
      incorrectAnswers: [],
      showEditModal: false,
      editingGame: null,
      games: [], // Array to store games with statistics
      showManageGamesModal: false,
      showUserManagementModal: false,
      showManageSquadsModal: false,
      squads: [], // Array to store squad data
      showMembersModal: false,
      squadMembers: [], // Array to store squad member usernames
      isMoving: false,
      moveStatus: null,
      showReviewLeaderboardsModal: false,
      showLeaderboardDetailsModal: false,
      leaderboards: [],
      selectedLeaderboard: null,
      sortedLeaderboardDetails: [],
      showSearchGamesModal: false,
      searchQuery: '',
      searchResults: [],
      allGames: [], // Will store all games for searching
    };
  },
  methods: {
    async createGame() {
      try {
        const gamesRef = collection(db, 'games');
        await addDoc(gamesRef, {
          questionText: this.questionText,
          questionShortDescription: this.questionShortDescription,
          liveDate: this.setNoonTime(this.liveDate),
          basePoints: this.basePoints,
          correctAnswers: this.correctAnswers,
          incorrectAnswers: this.incorrectAnswers,
          createdAt: serverTimestamp(),
        });

        alert('Game created successfully!');
        this.resetForm();
        this.showCreateGameForm = false;
        this.$refs.listGames.fetchGames();
      } catch (error) {
        console.error('Error creating game: ', error);
      }
    },
    generateCorrectAnswerFields() {
      this.correctAnswers = Array(this.correctAnswerCount).fill('');
    },
    generateIncorrectAnswerFields() {
      this.incorrectAnswers = Array(this.incorrectAnswerCount).fill('');
    },
    resetForm() {
      this.questionText = '';
      this.questionShortDescription = '';
      this.liveDate = '';
      this.basePoints = 100;
      this.correctAnswerCount = 0;
      this.incorrectAnswerCount = 0;
      this.correctAnswers = [];
      this.incorrectAnswers = [];
      this.showCreateGameForm = false;
    },
    openEditModal(game) {
      this.editingGame = JSON.parse(JSON.stringify(game));
      // Convert liveDate to YYYY-MM-DD format for the date input
      if (this.editingGame.liveDate) {
        let date;
        if (this.editingGame.liveDate.seconds) {
          // If it's a Firestore Timestamp
          date = new Date(this.editingGame.liveDate.seconds * 1000);
        } else if (this.editingGame.liveDate instanceof Date) {
          // If it's already a Date object
          date = this.editingGame.liveDate;
        } else {
          // If it's a string, try to parse it
          date = parse(this.editingGame.liveDate, 'yyyy-MM-dd', new Date());
        }

        if (isValid(date)) {
          this.editingGame.liveDate = format(date, 'yyyy-MM-dd');
        } else {
          console.error('Invalid date:', this.editingGame.liveDate);
          this.editingGame.liveDate = ''; // Set to empty string if invalid
        }
      } else {
        this.editingGame.liveDate = ''; // Set to empty string if null or undefined
      }
      this.showEditModal = true;
    },
    closeEditModal() {
      console.log('Closing edit modal');
      this.editingGame = null;
      this.showEditModal = false;
    },
    addAnswer(type) {
      if (type === 'correct') {
        this.editingGame.correctAnswers.push('');
      } else {
        this.editingGame.incorrectAnswers.push('');
      }
    },
    async updateGame() {
      console.log('updateGame method called');
      try {
        const gameRef = doc(db, 'games', this.editingGame.id);
        console.log('Updating game with ID:', this.editingGame.id);
        const updateData = {
          questionText: this.editingGame.questionText,
          questionShortDescription: this.editingGame.questionShortDescription,
          liveDate: this.setNoonTime(this.editingGame.liveDate),
          basePoints: this.editingGame.basePoints,
          correctAnswers: this.editingGame.correctAnswers,
          incorrectAnswers: this.editingGame.incorrectAnswers,
          updatedAt: serverTimestamp(),
        };
        console.log('Update data:', updateData);
        await updateDoc(gameRef, updateData);
        console.log('Game updated successfully');
        alert('Game updated successfully!');
        this.closeEditModal();
        // Refresh the games list
        this.$refs.listGames.fetchGames();
      } catch (error) {
        console.error('Error updating game: ', error);
        alert('Error updating game: ' + error.message);
      }
    },
    setNoonTime(dateString) {
      console.log('Setting noon time for:', dateString);
      const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
      const noonTime = setSeconds(setMinutes(setHours(parsedDate, 12), 0), 0);
      console.log('Noon time:', noonTime);
      return noonTime;
    },
    resetView() {
      this.showCreateGameForm = false;
      this.showUserManagement = false;
      this.showManageGamesModal = false;
    },
    async fetchGameStatistics() {
      try {
        const gamesRef = collection(db, 'games');
        const gamesSnapshot = await getDocs(gamesRef);
        this.games = gamesSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            questionText: data.questionText,
            timesPlayed: data.timesPlayed || 0,
            timesShared: data.timesShared || 0,
            correctPercentage: this.calculatePercentage(data.correctAnswersCount, data.timesPlayed),
            bankedPercentage: this.calculatePercentage(data.bankedCount, data.timesPlayed),
            bombPercentage: this.calculatePercentage(data.bombCount, data.timesPlayed),
          };
        });
      } catch (error) {
        console.error('Error fetching game statistics:', error);
      }
    },
    calculatePercentage(part, total) {
      return total > 0 ? ((part / total) * 100).toFixed(2) : 0;
    },
    openUserManagementModal() {
      this.showUserManagementModal = true;
    },
    closeUserManagementModal() {
      this.showUserManagementModal = false;
    },
    async fetchSquadData() {
      try {
        const squadsRef = collection(db, 'squads');
        const squadsSnapshot = await getDocs(squadsRef);
        this.squads = squadsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.squadName || 'Unnamed Squad',
            memberCount: data.members ? data.members.length : 0,
          };
        });
      } catch (error) {
        console.error('Error fetching squad data:', error);
      }
    },
    openManageSquadsModal() {
      this.fetchSquadData();
      this.showManageSquadsModal = true;
    },
    closeManageSquadsModal() {
      this.showManageSquadsModal = false;
    },
    async viewSquadMembers(squadId) {
      try {
        const squadDocRef = doc(db, 'squads', squadId);
        const squadDoc = await getDoc(squadDocRef);

        if (squadDoc.exists()) {
          const squadData = squadDoc.data();
          const memberIds = squadData.members || [];
          this.squadMembers = await this.fetchUsernames(memberIds);
          this.showMembersModal = true;
        }
      } catch (error) {
        console.error('Error fetching squad members:', error);
      }
    },
    async fetchUsernames(memberIds) {
      const usernames = [];
      for (const userId of memberIds) {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          usernames.push(userData.username || 'Unknown');
        }
      }
      return usernames;
    },
    closeMembersModal() {
      this.showMembersModal = false;
    },
    async openReviewLeaderboardsModal() {
      this.showReviewLeaderboardsModal = true;
      await this.fetchLeaderboards();
    },

    closeReviewLeaderboardsModal() {
      this.showReviewLeaderboardsModal = false;
    },

    closeLeaderboardDetailsModal() {
      this.showLeaderboardDetailsModal = false;
      this.selectedLeaderboard = null;
      this.sortedLeaderboardDetails = [];
    },

    async fetchLeaderboards() {
      try {
        const leaderboardsRef = collection(db, 'weeklyLeaderboard');
        const leaderboardsSnapshot = await getDocs(leaderboardsRef);
        
        this.leaderboards = leaderboardsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })).sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
      } catch (error) {
        console.error('Error fetching leaderboards:', error);
      }
    },

    async reviewLeaderboard(leaderboard) {
      this.selectedLeaderboard = leaderboard;
      
      if (leaderboard.scores) {
        try {
          // Calculate scores for each player from their games array
          const scoresArray = Object.entries(leaderboard.scores).map(([uid, data]) => {
            // Calculate total score from games array
            const calculatedScore = data.games?.reduce((total, game) => {
              return total + (game.score || 0);
            }, 0) || 0;

            return {
              uid,
              username: data.username || 'Unknown',
              weeklyScore: data.weeklyScore || 0,
              calculatedScore: calculatedScore,
              games: data.games?.map(game => ({
                ...game,
                timestamp: game.timestamp || 'Unknown time'
              })).sort((a, b) => {
                // Sort games by timestamp if available
                if (a.timestamp && b.timestamp) {
                  return new Date(b.timestamp) - new Date(a.timestamp);
                }
                return 0;
              }) || []
            };
          });
          
          console.log('Scores array:', scoresArray);
          
          this.sortedLeaderboardDetails = scoresArray.sort((a, b) => b.weeklyScore - a.weeklyScore);
        } catch (error) {
          console.error('Error calculating scores:', error);
          console.error('Error details:', {
            scores: leaderboard.scores
          });
          this.sortedLeaderboardDetails = [];
        }
      } else {
        this.sortedLeaderboardDetails = [];
      }
      
      this.showLeaderboardDetailsModal = true;
    },

    formatDate(date) {
      if (!date) return '';
      
      let dateObj;
      if (date.seconds) {
        // Handle Firestore timestamp
        dateObj = new Date(date.seconds * 1000);
      } else if (date instanceof Date) {
        dateObj = date;
      } else {
        // Try to parse string date
        dateObj = new Date(date);
      }
      
      if (isNaN(dateObj.getTime())) return 'Invalid Date';
      
      return dateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    openSearchGamesModal() {
      this.showSearchGamesModal = true;
      this.fetchAllGames();
    },

    closeSearchGamesModal() {
      this.showSearchGamesModal = false;
      this.searchQuery = '';
      this.searchResults = [];
    },

    async fetchAllGames() {
      try {
        const gamesRef = collection(db, 'games');
        const gamesSnapshot = await getDocs(gamesRef);
        this.allGames = gamesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    },

    searchGames() {
      const query = this.searchQuery.toLowerCase();
      this.searchResults = this.allGames.filter(game => 
        game.questionText?.toLowerCase().includes(query) ||
        game.questionShortDescription?.toLowerCase().includes(query)
      );
    },

    async fixPlayerScore(player) {
      try {
        const leaderboardRef = doc(db, 'weeklyLeaderboard', this.selectedLeaderboard.id);
        
        await updateDoc(leaderboardRef, {
          [`scores.${player.uid}.weeklyScore`]: player.calculatedScore
        });

        // Update the local state
        const playerIndex = this.sortedLeaderboardDetails.findIndex(p => p.uid === player.uid);
        if (playerIndex !== -1) {
          this.sortedLeaderboardDetails[playerIndex].weeklyScore = player.calculatedScore;
        }

        // Re-sort the leaderboard
        this.sortedLeaderboardDetails.sort((a, b) => b.weeklyScore - a.weeklyScore);

        alert('Score updated successfully!');
      } catch (error) {
        console.error('Error fixing score:', error);
        alert('Error updating score: ' + error.message);
      }
    },
  },
  mounted() {
    this.fetchGameStatistics();
  },
};
</script>

<style scoped>
.admin-dashboard {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  color: #2c3e50;
  text-align: center;
}

.create-game-form {
  margin-top: 20px;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.form-control {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.answer-field {
  margin-bottom: 10px;
}

.btn {
  padding: 10px 20px;
  font-size: 16px;
  margin-right: 10px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  color: white;
}

.btn-success {
  background-color: #28a745;
  border: none;
  color: white;
}

.btn-secondary {
  background-color: #6c757d;
  border: none;
  color: white;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.admin-section {
  margin: 20px 0;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.admin-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.success {
  color: #4CAF50;
  margin-top: 10px;
}

.error {
  color: #f44336;
  margin-top: 10px;
}

.leaderboard-table, .leaderboard-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.leaderboard-table th,
.leaderboard-table td,
.leaderboard-details-table th,
.leaderboard-details-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.leaderboard-table th,
.leaderboard-details-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.leaderboard-table tr:hover,
.leaderboard-details-table tr:hover {
  background-color: #f5f5f5;
}

.search-results {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 20px;
}

.no-results {
  text-align: center;
  padding: 20px;
  color: #666;
}

.score-mismatch {
  background-color: #ffebee;
}

.score-mismatch td {
  color: #d32f2f;
}

.game-details {
  max-width: 300px;
  font-size: 0.9em;
}

.game-details hr {
  margin: 8px 0;
  border: 0;
  border-top: 1px solid #eee;
}

.leaderboard-details-table td {
  vertical-align: top;
  padding: 12px;
}

.btn-warning {
  background-color: #ffc107;
  color: #000;
  border: none;
  padding: 5px 10px;
  font-size: 0.9em;
}

.btn-warning:hover {
  background-color: #e0a800;
}
</style>


